body {
  margin: 0;
  padding: 0;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.menu-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-style: oblique;
  font-size: 24px;
  color: white;
}

.menu-items li {
  margin-right: 1rem;
}

.menu-items a {
  color: inherit;
  text-decoration: none;
}


.logo {
  height: 50px;
}

.menu-items a {
  color: inherit;
  text-decoration: none;
  position: relative; /* New */
}

.menu-items a::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Adjust this value to place the bar correctly under the menu item */
  left: 0;
  height: 2px; /* The height of the bar */
  width: 0;
  background-color: #1e90ff; /* The color of the bar */
  transition: width .3s ease; /* Animation of the bar appearing */
}

.menu-items a:hover::after {
  width: 100%; /* The bar will take the full width of the menu item when hovered */
}

.hamburger {
  display: none;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  }

  @media (max-width: 600px) {

    .logo {
      height: 30px;
    }

    .hamburger {
      display: block;
      font-size: 24px;
      color: white;
      background: transparent;
      border: none;
      z-index: 2;
      }

    .menu-items {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgb(0, 0, 0);
      transform: translateX(-100%);
      transition: transform 0.3s ease-out;
    }

    .menu-items.active {
      transform: translateX(0);
    }
  
    .menu-items li {
      margin: 1rem 0;
    }
  }

    

