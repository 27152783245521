#qualifications {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#qualification-title
{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  font-size: 50px;
  margin-top: 1%;
}

#qualification-subtitle
{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: rgb(150, 150, 150);
  font-size: 18px;
  margin-top: -30px;
}

.header-left-qualifications {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  height: 70vh;
  color: white;
  margin-top: 10px;
  margin-right: 5%;
}

.header-right-qualifications {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  height: 70vh;
  color: white;
  margin-top: 10px;
  margin-left: 5%;
}

.header-left-qualifications h1 {
  font-size: 32px;
}

.header-right-qualifications h1 {
  font-size: 32px;
}

.school {
  margin-bottom: 10px;
}

.school h3 {
  /* Styles for school names */
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.school p {
  /* Styles for school details */
  color: rgb(150, 150, 150);
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
}

.experience {
  margin-bottom: 10px;
}

.experience h3 {
  /* Styles for school names */
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.experience p {
  /* Styles for school details */
  color: rgb(150, 150, 150);
  font-size: 24px;
  margin-bottom: 5px;
  text-align: center;
}

#resume-download-section {
  display: flex;
  justify-content: center;  /* Center the button horizontally */
  align-items: center;      /* Center the button vertically */
}

#resume-download-button {
  padding: 10px 20px;       /* Add some padding around the text */
  font-size: 18px;          /* Set the font size */
  border: none;             /* Remove the default border */
  background-color: #1e90ff; /* Set the background color */
  color: white;             /* Set the text color */
  cursor: pointer;          /* Change the cursor when hovering over the button */
  text-decoration: none;    /* Remove the default underline */
  margin-top: -160px;
}

#resume-download-button:hover {
  background-color: #1c86ee; /* Change the background color when hovering */
}

@media (max-width: 600px) {
  #qualification-title
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 28px;
    margin-top: -640px;
  }

  #qualification-subtitle
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: rgb(150, 150, 150);
    font-size: 14px;
    margin-top: -15px;
  }

  .header-left-skills {
    height: 255vh;
  }

  .header-right-skills {
    height: 255vh;
  }

  .header-left-qualifications h1 {
    font-size: 24px;
  }
  
  .header-right-qualifications h1 {
    font-size: 24px;
  }

  .school h3 {
    /* Styles for school names */
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .school p {
    /* Styles for school details */
    color: rgb(150, 150, 150);
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
  }

  .experience h3 {
    /* Styles for school names */
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .experience  p {
    /* Styles for school details */
    color: rgb(150, 150, 150);
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
  }

  .header-left-skills {
    height: 255vh;
  }

  .header-right-skills {
    height: 255vh;
  }

  .header-left-qualifications {
   margin-left:10px
  }

  .header-right-qualifications {
    margin-right:10px
   }
}
