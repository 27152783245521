/* #projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: 100vh;
    width: 100vh;
  }
   */

   #projects {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -140px;
  }
  
  #project-title
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 50px;
    margin-top: 14%;
  }
  
  #project-subtitle
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: rgb(150, 150, 150);
    font-size: 18px;
    margin-top: -30px;
  }

  .card {
    position: relative;
    width: 300px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s; /* Added transform transition */
    cursor: pointer;
  }
  
  .card-button {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #000000;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Added transform transition */
  }
  
  .card:hover {
    transform: translateY(-10px); /* Lift-up effect for the card */
  }
  
  .card:hover img {
    filter: brightness(70%); /* Added blue veil effect for the image */
  }
  
  .card:hover .card-button {
    display: block;
    background-color: #424242; /* Changed to blue shade */
  }

  #resume-download-section {
    margin-top: -130px; /* Adjust this value according to your needs */
    margin-bottom: 150px;
}

  @media (max-width: 600px) {
    #projects {
      display: flex;
      flex-direction: column; /* Added to stack children vertically */
      justify-content: center;
      align-items: center;
      height: auto; /* Changed to auto to allow dynamic height as per content */
      margin-top: -40px;
    }
    .card {
      width: 90%; /* Updated to take 90% of the width of the parent container. Change to 100% if you want it to take the full width */
      margin: 20px auto; /* Updated margin to auto to center the card */
    }
    #project-title
    {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: white;
      font-size: 28px;
      margin-top: 290px;
    }

    #project-subtitle
    {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: rgb(150, 150, 150);
      font-size: 14px;
      margin-top: -15px;
      margin-bottom: 40px;
    }
    #resume-download-section {
      margin-top:30px
  }
}
