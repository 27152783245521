body {
    margin: 0;
    padding: 0;
    background-color: black;
    height: 100vh;
  }  

  #contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -140px;
  }

  #contact-title
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  
  .header-left-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 70vh;
    color: white;
    margin-top: 10px;
  }
  
  .header-right-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 70vh;
    color: white;
    margin-top: 10px;
  }

  
.contactCard {
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contactCard .icon {
    font-weight: 600;
    font-size: 1.2rem;
    color: #1e90ff;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .contactCard .icon > * {
    background: lightblue;
    border-radius: 50%;
    padding: 0.5rem;
  }
  
  .contactCard .title {
    font-size: 1.1rem;
    font-weight: 600;
    color: black; /* Set the color of the title */
    background: rgb(255, 255, 255);
  }
  
  .contactCard .description {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .contactCard .icon .title {
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(0, 0, 0); /* Set the color of the title */
  }

  .button {
    margin-top: 10px;
    width: 100%;
    background-color: #1e90ff;
    color: lightblue;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    transition: background-color 300ms ease-in;
  }
  
  .button:hover {
    background-color: rgb(65, 65, 235)
  }

  .image {
    max-width: 90%;
    max-height: 90%;
    margin: -30px;
    display: block;
    align-items: flex-start;

  }

  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem; /* Add some horizontal spacing */
  }

  @media (max-width: 600px) {

    #contact-title
    {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: white;
      font-size: 28px;
      margin-top: 80px;
    }

    #contacts {
      flex-direction: column; /* stack children vertically */
      height: 750px; /* adjust height according to content */
    }

    .image {
      max-width: 180%;
      max-height: 180%;
      margin: -30px;
      display: block;
      align-items: flex-start;
      margin-top: 220px;
      margin-bottom: 60px;
  
    }

    .contactCard {
      width: 180%;
  }
  }
  