body {
    margin: 0;
    padding: 0;
    background-color: black;
    height: 100vh;
  }  

  #skills {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  #skills-title
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 50px;
    margin-top: 10px;
  }

  #skills-subtitle
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: rgb(150, 150, 150);
    font-size: 18px;
    margin-top: -30px;
  }
  
  .header-left-skills {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 74vh;
    color: white;
    margin-top: 10px;
  }
  
  .header-right-skills {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 74vh;
    color: white;
    margin-top: 10px;
  }

  .header-left-skills h1 {
    font-size: 32px;
  }
  
  .header-right-skills h1 {
    font-size: 32px;
  }

.image-container-front-end,
.image-container-back-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-container-front-end div,
.image-container-back-end div {
  width: 33.33%;
  margin-bottom: 30px;
}

.image-container-front-end img,
.image-container-back-end img {
  border-radius: 20%;
  background-color: #ffffff16;
  width: 50%;
  height: 50%;
  margin-right: 30px;
  margin-left:30px;
}

.image-container-front-end span,
.image-container-back-end span {
  color: white;
  font-size: 18px;
  display: block;
  width: 100%;
  text-align: center;
}


@media (max-width: 600px) {
  #skills-title
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 28px;
    margin-top: 10px;
  }

  #skills-subtitle
  {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: rgb(150, 150, 150);
    font-size: 14px;
    margin-top: -15px;
  }

  .header-left-skills h1 {
    font-size: 24px;
  }
  .header-right-skills h1 {
    font-size: 24px;
  }

  .image-container-front-end,
  .image-container-back-end {
    flex-direction: column;
  }

  .image-container-front-end div,
  .image-container-back-end div {
    width: 100%;
  }

  .image-container-front-end img,
  .image-container-back-end img {
    width: 70%; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: auto;
    margin-left: auto;
  }

  .header-left-skills {
    height: 70vh;
  }

  .header-right-skills {
    height: 70vh;
  }
}

  
  