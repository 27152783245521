body {
    margin: 0;
    padding: 0;
    background-color: black;
    height: 100vh;
  }  

  .header-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100vh;
    color: white;
    text-align: left;
  }
  
  .header-left h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .header-left h3 {
    font-size: 32px;
    margin: 0;
  }
  
  .header-right {
    display: inline-block;
    width: 50%;
  }

  .image-container {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 600px;
  }

  @media (max-width: 600px) {
    .image-container {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 400px;
    }

    .header-left h1 {
      font-size: 28px;
      margin-bottom: 10px;
      margin-left: 5px;
    }

    .header-left h3 {
      font-size: 18px;
      margin: 0;
    }

  }
  